import React, { FC } from 'react';
import ModalPortal from './ModalPortal';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean;
  onClose: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const Modal: FC<Props> = (props) => {
  const { className, visible, onClose, ...restProps } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = '';
    };
  }, [visible]);

  if (!visible) {
    return <></>;
  }

  return (
    <ModalPortal>
      <div
        className={classnames('popup_wrap', className)}
        onClick={() => {
          onClose && onClose();
        }}
      >
        <div className="popup_body" onClick={(e) => e.stopPropagation()}>
          <div className="scroll_area">
            <div className="popup_content" {...restProps}>
              <div className="section_info">
                <p className="desc">{t('bookmark.notice')}</p>
              </div>
            </div>
            <button
              type="button"
              className="popup_close"
              onClick={() => {
                onClose();
              }}
            >
              <span className="text">{t('session.detail.close')}</span>
              <span className="bar1"></span>
              <span className="bar2"></span>
            </button>
          </div>
        </div>
        <div className="dimmed"></div>
      </div>
    </ModalPortal>
  );
};

export default Modal;
