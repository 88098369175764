import { useI18next } from 'gatsby-plugin-react-i18next';
import { formatLocale } from '@/utils/utility';

export const useLandpressI18next = () => {
  const props = useI18next();

  return {
    ...props,
    currentLocale: formatLocale(props.language),
  };
};
