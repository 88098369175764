import { Session } from '@/types/session';
import { useLandpressI18next } from '@/hooks/i18next';
import { useStaticQuery, graphql } from 'gatsby';

type SessionsQueryType = {
  allLandPressSession: {
    nodes: Array<Session>;
  };
};
export const getSessions = () => {
  const { currentLocale } = useLandpressI18next();
  const {
    allLandPressSession: { nodes: data },
  } = useStaticQuery<SessionsQueryType>(graphql`
    query MyQuery {
      allLandPressSession {
        nodes {
          postId
          locale
          title
          detail
          track
          panel_discussion
          speeching_language
          start_at
          end_at
          break_time
          is_keynote
          archive_link_ko
          archive_link_ja
          archive_link_en
          deck_link_en
          deck_link_ja
          deck_link_ko
          use_qna
          ics {
            fullUrl
          }
          categories {
            postId
            name
          }
          speakers {
            name
            company
            department
            introduction
            job_title
            profile_image {
              fullUrl
            }
          }
          related_sessions {
            postId
            start_at
          }
          og_image {
            fullUrl
          }
          avatar {
            fullUrl
          }
        }
      }
    }
  `);

  return data.filter((v) => v.locale === currentLocale);
};
