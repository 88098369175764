import { DateTime, Duration } from 'luxon';

import liff from '@/utils/liff';
import { BreakTimeType, MediaType, Track } from '@/types/session';
import { TimeType } from '@/types/time';

export const getOAuthToken = async () => {
  const liffToken = await liff.getAccessToken();

  return `Bearer ${liffToken}`;
};

export const generateSimpleKey = () => '_' + Math.random().toString(36).substr(2, 9);

export const formatTime = (time: string) => {
  return DateTime.fromJSDate(new Date(time), { zone: 'japan' }).toFormat('HH:mm');
};

export const formatLocale = (locale: string) => {
  const text = {
    en: 'en_US',
    ko: 'ko_KR',
    ja: 'ja_JP',
  }[locale];

  return text;
};

export const getImageUrl = (media: MediaType[]) => {
  return media[0].fullUrl;
};

export const getTwitterShareLink = (text: string) => {
  if (typeof window !== `undefined`) {
    return `https://twitter.com/intent/tweet?hashtags=linedevday&text=${text}&url=${location.href}`;
  }
  return '';
};

export const getSessionGoogleCalendarLink = (data: { text: string; startAt: string; endAt: string }) => {
  const { text, startAt, endAt } = data;

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURI(text)}&dates=${DateTime.fromISO(
    startAt,
    {
      setZone: true,
    }
  ).toFormat("yyyyMMdd'T'HHmmss'Z'")}/${DateTime.fromISO(endAt, { setZone: true }).toFormat("yyyyMMdd'T'HHmmss'Z'")}`;
};

export const filteredDay = (track: Track) => {
  if (
    track === Track.DAY1_TRACK1 ||
    track === Track.DAY1_TRACK2 ||
    track === Track.DAY1_TRACK3 ||
    track === Track.DAY1_TRACK4
  ) {
    return '1';
  }

  return '2';
};

export const filteredTrack = (track: Track) => {
  if (track === Track.DAY1_TRACK1 || track === Track.DAY2_TRACK1) {
    return '1';
  }
  if (track === Track.DAY1_TRACK2 || track === Track.DAY2_TRACK2) {
    return '2';
  }
  if (track === Track.DAY1_TRACK3 || track === Track.DAY2_TRACK3) {
    return '3';
  }
  if (track === Track.DAY1_TRACK4 || track === Track.DAY2_TRACK4) {
    return '4';
  }

  return;
};

export const formattingDurationTime = (start: string, end: string) => {
  const diff = DateTime.fromJSDate(new Date(end)).toMillis() - DateTime.fromJSDate(new Date(start)).toMillis();
  const TWENTY_MINUTE_TO_MILLIS = Duration.fromObject({ minutes: 20 }).toMillis();

  if (diff <= TWENTY_MINUTE_TO_MILLIS) {
    return '20min';
  }

  return '40min';
};

export const formattingBreakTime = (time: BreakTimeType) => {
  if (time === BreakTimeType.TEN_MINUTE) {
    return 10;
  }

  if (time === BreakTimeType.TWENTY_MINUTE) {
    return 20;
  }

  if (time === BreakTimeType.THIRTY_MINUTE) {
    return 30;
  }

  return 0;
};

export const formatLiveUrl = (track: Track) => {
  if (track === Track.DAY1_TRACK1 || track === Track.DAY2_TRACK1) {
    return '/live/track1';
  }
  if (track === Track.DAY1_TRACK2 || track === Track.DAY2_TRACK2) {
    return '/live/track2';
  }
  if (track === Track.DAY1_TRACK3 || track === Track.DAY2_TRACK3) {
    return '/live/track3';
  }
  if (track === Track.DAY1_TRACK4 || track === Track.DAY2_TRACK4) {
    return '/live/track4';
  }

  return '';
};

export const getDay1Time = () => {
  const currentTime = DateTime.fromJSDate(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const diffDays = DateTime.fromISO('2021-11-10').diff(currentTime, ['days']).toObject().days;

  return currentTime.plus({ days: diffDays }).setZone('JST');
};

export const getDay2Time = () => {
  const currentTime = DateTime.fromJSDate(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const diffDays = DateTime.fromISO('2021-11-11').diff(currentTime, ['days']).toObject().days;

  return currentTime.plus({ days: diffDays }).setZone('JST');
};

export const getDay = () => {
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();

  const DAY1_TIME = getDay1Time().toUTC().toMillis();
  const DAY2_TIME = getDay2Time().toUTC().toMillis();

  if (DAY1_TIME <= currentTimeToUTC && currentTimeToUTC < DAY2_TIME) {
    return '1';
  } else {
    return '2';
  }
};

export const getTimeType = () => {
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();
  const DEFAULT_DAY1_TIME = getDay1Time();
  const DEFAULT_DAY2_TIME = getDay2Time();

  const DAY1_START_TIME = DEFAULT_DAY1_TIME.toUTC().toMillis();
  const DAY2_START_TIME = DEFAULT_DAY2_TIME.toUTC().toMillis();
  const DAY1_1230 = DEFAULT_DAY1_TIME.set({ hour: 12, minute: 30 }).toUTC().toMillis();
  const DAY1_1300 = DEFAULT_DAY1_TIME.set({ hour: 13, minute: 0 }).toUTC().toMillis();
  const DAY1_1340 = DEFAULT_DAY1_TIME.set({ hour: 13, minute: 40 }).toUTC().toMillis();
  const DAY1_1740 = DEFAULT_DAY1_TIME.set({ hour: 17, minute: 40 }).toUTC().toMillis();
  const DAY2_1300 = DEFAULT_DAY2_TIME.set({ hour: 13, minute: 0 }).toUTC().toMillis();
  const DAY2_1720 = DEFAULT_DAY2_TIME.set({ hour: 17, minute: 20 }).toUTC().toMillis();
  const ENDED_FESTIVAL = DEFAULT_DAY2_TIME.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .toUTC()
    .toMillis();

  if (currentTimeToUTC < DAY1_START_TIME) {
    return TimeType.BEFORE_DEVDAY;
  }

  if (DAY1_START_TIME <= currentTimeToUTC && currentTimeToUTC < DAY1_1230) {
    return TimeType.DAY1_BEFORE1230;
  }

  if (DAY1_1230 <= currentTimeToUTC && currentTimeToUTC < DAY1_1300) {
    return TimeType.DAY1_1230TO1259;
  }

  if (DAY1_1300 <= currentTimeToUTC && currentTimeToUTC < DAY1_1340) {
    return TimeType.DAY1_1300TO1339;
  }

  if (DAY1_1340 <= currentTimeToUTC && currentTimeToUTC < DAY1_1740) {
    return TimeType.DAY1_AFTER1340;
  }

  if (DAY1_1740 <= currentTimeToUTC && currentTimeToUTC < DAY2_START_TIME) {
    return TimeType.DAY1_ENDED_TRACK;
  }

  if (DAY2_START_TIME <= currentTimeToUTC && currentTimeToUTC < DAY2_1300) {
    return TimeType.DAY2_BEFORE1300;
  }

  if (DAY2_1300 <= currentTimeToUTC && currentTimeToUTC < DAY2_1720) {
    return TimeType.DAY2_AFTER1300;
  }

  if (DAY2_1720 <= currentTimeToUTC && currentTimeToUTC <= ENDED_FESTIVAL) {
    return TimeType.DAY2_ENDED_TRACK;
  }

  if (ENDED_FESTIVAL < currentTimeToUTC) {
    return TimeType.ENDED_FESTIVAL;
  }

  return TimeType.BEFORE_DEVDAY;
};
