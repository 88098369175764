import { api } from '@/commons/axios';
import { getOAuthToken } from '@/utils/utility';
import { API_CONFIG } from '@/commons/config';
import { Participant } from '@/types/participant';

export const fetchMe = async () => {
  const OauthToken = await getOAuthToken();

  const res = await api.request<Participant[]>({
    method: 'GET',
    url: `contents/projects/${API_CONFIG.PROJECT_ID}/collections/${API_CONFIG.COLLECTION.PARTICIPANT}/items/my`,
    headers: {
      'X-OAUTH-TOKEN': OauthToken,
    },
  });

  if (!res.data) {
    return null;
  }

  return res.data[0];
};

type myPostType = {
  name: string;
  company: string;
  country: string;
  job_title: string;
  information_language: string;
  interested_areas: number[];
  published: boolean;
};

export const createMe = async (data: myPostType) => {
  const OauthToken = await getOAuthToken();

  const res = await api.request({
    method: 'POST',
    url: `contents/projects/${API_CONFIG.PROJECT_ID}/collections/${API_CONFIG.COLLECTION.PARTICIPANT}/items/my`,
    headers: {
      'X-OAUTH-TOKEN': OauthToken,
    },
    data: data,
  });

  return res;
};

export const updateBookmark = async (id: number | string, data: number[]) => {
  const OauthToken = await getOAuthToken();

  const res = await api.request({
    method: 'PUT',
    url: `contents/projects/${API_CONFIG.PROJECT_ID}/collections/${API_CONFIG.COLLECTION.PARTICIPANT}/items/my/${id}`,
    headers: {
      'X-OAUTH-TOKEN': OauthToken,
    },
    data: {
      bookmark_sessions: data,
    },
  });

  return res;
};
