export type HLSURLs = {
  144: null | string;
  240: null | string;
  360: null | string;
  480: null | string;
  720: null | string;
  1080: null | string;
  abr: null | string;
  aac: null | string;
};

export type ThumbnailURLs = {
  small: string;
  large: string;
  commonLarge: string;
  commonSmall: string;
  large1x1: string;
  small1x1: string;
  landscape: string;
  swipe: string;
};

export enum LiveStatus {
  LIVE = 'LIVE',
  FINISHED = 'FINISHED',
  SUSPEND = 'SUSPEND',
}

export enum ArchiveStatus {
  ARCHIVED = 'ARCHIVED',
  NOT_ARCHIVED = 'NOT_ARCHIVED',
  RECORDING = 'RECORDING',
}

export type LiveInfo = {
  item: {
    id: number;
    channelId: number;
    title: string;
    description: string;
    thumbnailURLs: ThumbnailURLs;
    liveStatus: LiveStatus;
    archiveStatus: ArchiveStatus;
  };
  liveHLSURLs: HLSURLs;
  archivedHLSURLs: HLSURLs;
  status: number;
};

export type LiveUrls = {
  track1_en: string;
  track2_en: string;
  track3_en: string;
  track4_en: string;
  track1_ja: string;
  track2_ja: string;
  track3_ja: string;
  track4_ja: string;
  track1_ko: string;
  track2_ko: string;
  track3_ko: string;
  track4_ko: string;
};

export type VideoDatum = {
  url: string | null;
  thumbnail: string;
};

export type VideoData = {
  '360P': VideoDatum;
  '480P': VideoDatum;
  '720P': VideoDatum;
  '1080P': VideoDatum;
};

export type LiveErrorStatus = {
  track1_en: boolean;
  track2_en: boolean;
  track3_en: boolean;
  track4_en: boolean;
  track1_ja: boolean;
  track2_ja: boolean;
  track3_ja: boolean;
  track4_ja: boolean;
  track1_ko: boolean;
  track2_ko: boolean;
  track3_ko: boolean;
  track4_ko: boolean;
};
