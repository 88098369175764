export type MediaType = {
  postId: number;
  bucketUrl: string;
  createdAt: string;
  filename: string;
  mime: string;
  fullUrl: string;
};

export type Speaker = {
  postId: number;
  name: string;
  company: string;
  department: string;
  introduction: string;
  job_title: string;
  profile_image: MediaType[];
  createdAt: string;
};

export type Category = {
  postId: number;
  name: string;
  locale: string;
};

export enum Language {
  en = 'en',
  jp = 'jp',
  kr = 'kr',
}

export enum TrackDay {
  day1 = '1',
  day2 = '2',
}

export enum Track {
  DAY1_TRACK1 = 'day1_track1',
  DAY1_TRACK2 = 'day1_track2',
  DAY1_TRACK3 = 'day1_track3',
  DAY1_TRACK4 = 'day1_track4',
  DAY2_TRACK1 = 'day2_track1',
  DAY2_TRACK2 = 'day2_track2',
  DAY2_TRACK3 = 'day2_track3',
  DAY2_TRACK4 = 'day2_track4',
}

export enum BreakTimeType {
  DEFAULT = '0',
  TEN_MINUTE = '10',
  TWENTY_MINUTE = '20',
  THIRTY_MINUTE = '30',
}

export type Session = {
  postId: number;
  title: string;
  avatar: MediaType[];
  avatar_static: MediaType[];
  locale: string;
  categories: Category[];
  detail: string;
  start_at: string;
  break_time: BreakTimeType;
  end_at: string;
  is_pickup: boolean;
  og_image: MediaType[];
  deck_link_en: string;
  deck_link_ja: string;
  deck_link_ko: string;
  archive_link_en: string;
  archive_link_ja: string;
  archive_link_ko: string;
  ics: MediaType[];
  related_belongings: Session[];
  related_sessions: Session[];
  panel_discussion: boolean;
  track: Track;
  speakers: Speaker[];
  speeching_language: Language;
  createdAt: string;
  updatedAt: string;
  is_keynote: boolean;
  use_qna: boolean;
};

export type Qna = { body: string; session_name: string; session_id: number };
