import { useState, useMemo } from 'react';
import { without, sortBy } from 'lodash';
import { intersection } from 'lodash/fp';
import { DateTime } from 'luxon';

import { fetchMe, updateBookmark } from '@/networks/auth';
import { Session } from '@/types/session';
import { getSessions } from '@/queries/session/session.queries';
import { getCategory } from '@/queries/category/category.queries';
import { useAuthStore } from './auth';
import { Participant } from '@/types/participant';
import { filteredDay, filteredTrack, formatLiveUrl, formatTime, getTwitterShareLink } from '@/utils/utility';
import { useLandpressI18next } from './i18next';
import { useHandleError } from './error';
import { useTimezoneSuffix } from './common';
import axios from 'axios';
import { ErrorResponse } from '@/types/error';
import liff from '@/utils/liff';
import { useI18next } from 'gatsby-plugin-react-i18next';

export const useSessions = () => {
  const sessions = getSessions();
  const categories = getCategory();
  const { me, bookmark } = useAuthStore();
  const [filteredCategoryList, setFilteredCategoryList] = useState<string[]>([]);
  const [isActiveBookmarkToggle, setIsActiveBookmarkToggle] = useState<boolean>(false);

  const filteredSessions = useMemo(() => {
    let list = sortBy(sessions, ['start_at', 'track']);

    if (isActiveBookmarkToggle) {
      list = list.filter((v) => bookmark.includes(v.postId));
    }

    if (!!filteredCategoryList.length) {
      return list.filter(
        (session) =>
          !!intersection(
            session.categories.map((v) => v.name),
            filteredCategoryList
          ).length
      );
    }

    return list;
  }, [sessions, filteredCategoryList, isActiveBookmarkToggle]);

  const handleClickBookmarkToggle = () => setIsActiveBookmarkToggle(!isActiveBookmarkToggle);

  const isCheckedLogin = () => !!me;

  const handleChangeCategory = (isChecked: boolean, categoryName: string) => {
    if (isChecked) {
      const newCategory = [...filteredCategoryList, categoryName];
      const isALL = newCategory.length === categories.length || categoryName === 'ALL';

      if (isALL) {
        setFilteredCategoryList([]);
      } else {
        setFilteredCategoryList(newCategory);
      }
    } else {
      setFilteredCategoryList([...filteredCategoryList.filter((v) => v !== categoryName)]);
    }
  };

  return {
    sessions,
    filteredSessions,
    filteredCategoryList,
    categories,
    bookmark,
    isActiveBookmarkToggle,
    isCheckedLogin,
    onChangeBookmarkToggle: handleClickBookmarkToggle,
    onChangeCategoryCheckBox: handleChangeCategory,
    sessionLength: filteredSessions.length,
  };
};

export const useSessionCard = (session: Session) => {
  const timezoneSuffix = useTimezoneSuffix();
  const { bookmark, me, setMe, setBookmark } = useAuthStore();
  const { handleError } = useHandleError();
  const title = session.title;
  const day = `DAY-${filteredDay(session.track)}`;
  const track = `Track ${filteredTrack(session.track)}`;
  const durationTime = `${formatTime(session.start_at)}-${formatTime(session.end_at)} ${timezoneSuffix}`;
  const categories = !!session.categories.length ? session.categories.map((category) => category.name).join(', ') : '';
  const isBookmark = bookmark.includes(session.postId);

  const handleClickBookmark = async (id: number) => {
    const newBookmark = bookmark.includes(id) ? without(bookmark, id) : [...bookmark, id];
    setBookmark(newBookmark);

    try {
      await updateBookmark((me as Participant).postId, newBookmark);
      const updatedMe = await fetchMe();

      setMe(updatedMe as Participant);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data as ErrorResponse;

        if (errorData.statusCode === 400) {
          liff.logout();
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
          return;
        }
      }
      handleError(error);
    }
  };

  const isCheckedLogin = () => !!me;

  return {
    title,
    day,
    track,
    durationTime,
    categories,
    isBookmark,
    speakers: session.speakers,
    isCheckedLogin,
    onClickBookmark: handleClickBookmark,
  };
};

export const useSessionDetailModal = (session: Session) => {
  const timezoneSuffix = useTimezoneSuffix();
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();
  const startTimeToUTC = DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis();
  const endTimeToUTC = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis();
  const { bookmark, me, setMe, setBookmark } = useAuthStore();
  const { handleError } = useHandleError();
  const { languages } = useLandpressI18next();
  const title = session.title;
  const day = `DAY${filteredDay(session.track)}`;
  const durationTime = `${formatTime(session.start_at)}-${formatTime(session.end_at)} ${timezoneSuffix}`;
  const categories = !!session.categories.length ? session.categories.map((category) => category.name).join(', ') : '';
  const speachLanguage = session.speeching_language;
  const translateLanguage = languages.filter((v) => v !== session.speeching_language).join(',');
  const isBookmark = bookmark.includes(session.postId);
  const twitterURL = getTwitterShareLink(session.title);
  const description = session.detail;
  const ICSFile = session.ics ? session.ics[0]?.fullUrl : '';
  const gooleCalendarDataFormat = { text: session.title, startAt: session.start_at, endAt: session.end_at };
  const isEndedSession = currentTimeToUTC > endTimeToUTC;
  const liveURL = formatLiveUrl(session.track);
  const isLive = startTimeToUTC <= currentTimeToUTC && currentTimeToUTC < endTimeToUTC;
  const track = `track${filteredTrack(session.track)}`;
  const isShowArchive =
    (session.archive_link_ja && !!session.archive_link_ja.length) ||
    (session.archive_link_en && !!session.archive_link_en.length) ||
    (session.archive_link_ko && !!session.archive_link_ko.length) ||
    (session.deck_link_en && !!session.deck_link_en.length) ||
    (session.deck_link_ja && !!session.deck_link_ja.length) ||
    (session.deck_link_ko && !!session.deck_link_ko.length);

  const handleClickBookmark = async (id: number) => {
    const newBookmark = bookmark.includes(id) ? without(bookmark, id) : [...bookmark, id];
    setBookmark(newBookmark);

    try {
      await updateBookmark((me as Participant).postId, newBookmark);
      const updatedMe = await fetchMe();

      setMe(updatedMe as Participant);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data as ErrorResponse;

        if (errorData.statusCode === 400) {
          liff.logout();
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
          return;
        }
      }

      handleError(error);
    }
  };

  const isCheckedLogin = () => !!me;

  return {
    title,
    day,
    durationTime,
    categories,
    speachLanguage,
    translateLanguage,
    isBookmark,
    twitterURL,
    description,
    ICSFile,
    gooleCalendarDataFormat,
    speakers: session.speakers,
    isEndedSession,
    liveURL,
    isLive,
    track,
    isShowArchive,
    relatedSessions: sortBy(session.related_sessions, ['start_at']) || [],
    isCheckedLogin,
    onClickBookmark: handleClickBookmark,
  };
};
