import React, { useCallback } from 'react';
import axios, { AxiosError } from 'axios';

import { ErrorCode, ErrorMessage, ErrorResponse } from '@/types/error';
import { useToast } from '@/hooks/toast';

export const useHandleError = () => {
  const { openErrorToast } = useToast();
  const handleError = useCallback((error: AxiosError | Error | any) => {
    const errorData = axios.isAxiosError(error)
      ? (error.response?.data as ErrorResponse)
      : ({ message: ErrorCode[error.message] } as ErrorResponse);

    openErrorToast(`${ErrorMessage.__DEFAULT__}\nError Code: ${errorData.message}`);
  }, []);

  return {
    handleError,
  };
};
