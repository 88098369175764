export enum TimeType {
  BEFORE_DEVDAY = 'BEFORE_DEVDAY',
  DAY1_BEFORE1230 = 'DAY1_BEFORE1230',
  DAY1_1230TO1259 = 'DAY1_1230TO1259',
  DAY1_1300TO1339 = 'DAY1_1300TO1339',
  DAY1_AFTER1340 = 'DAY1_AFTER1340',
  DAY1_ENDED_TRACK = 'DAY1_ENDED_TRACK',
  DAY2_BEFORE1300 = 'DAY2_BEFORE1300',
  DAY2_AFTER1300 = 'DAY2_AFTER1300',
  DAY2_ENDED_TRACK = 'DAY2_ENDED_TRACK',
  ENDED_FESTIVAL = 'ENDED_FESTIVAL',
}
