import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';

import { Session, TrackDay } from '@/types/session';
import { useSessionCard } from '@/hooks/session';
import { filteredDay } from '@/utils/utility';
import NeedLoginModal from '@/components/commons/NeedLoginModal';

export type Props = {
  type?: 'basic' | 'relatedSession';
  session: Session;
} & React.HTMLAttributes<HTMLLIElement>;

const SessionCard: FC<Props> = (props) => {
  const { type = 'basic', session, ...restProps } = props;
  const { title, durationTime, track, speakers, isBookmark, categories, day } = useSessionCard(session);

  const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);

  return (
    <>
      <li
        className={classnames('session_card', [
          filteredDay(session.track) === TrackDay.day1 ? 'session_wed' : 'session_thu',
        ])}
        {...restProps}
      >
        <Link to={`/sessions/${session.postId}`} state={{ modal: true }}>
          {type === 'basic' && (
            <>
              <div>
                <strong className="day">{day}</strong>
                <span className="track">{track}</span>
              </div>
              <span className="time">{durationTime}</span>
            </>
          )}
          {type === 'relatedSession' && (
            <div>
              <strong className="day">{day}</strong>
              <span className="time">{durationTime}</span>
            </div>
          )}
          <p className="category">{categories}</p>
          <h5 className="title">{title}</h5>
          {session.panel_discussion && type === 'basic' && <span className="badge_aftertalk">Panel Discussion</span>}
          <p className="speaker">
            {type === 'basic' && (
              <>
                {speakers.map((speaker, index) => (
                  <span key={index}>{speaker.name}</span>
                ))}
              </>
            )}
            {type === 'relatedSession' && (
              <>
                {speakers.map((speaker, index) => {
                  if (index % 2 !== 0) {
                    return;
                  }

                  return (
                    <span key={index}>
                      <span>{speakers[index].name}</span>
                      {speakers[index + 1] && <span>{speakers[index + 1].name}</span>}
                    </span>
                  );
                })}
              </>
            )}
          </p>
        </Link>
      </li>
      <NeedLoginModal
        className="bookmark_popup"
        visible={showNeedLoginModal}
        onClose={() => setShowNeedLoginModal(false)}
      />
    </>
  );
};

export default SessionCard;
