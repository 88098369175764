export const API_CONFIG = {
  BASE_URL: process.env.GATSBY_LANDPRESS_HOST,
  PROJECT_ID: process.env.GATSBY_LANDPRESS_PROJECT_ID ? process.env.GATSBY_LANDPRESS_PROJECT_ID : 534,
  COLLECTION: {
    PARTICIPANT: process.env.GATSBY_PHASE === 'real-test' ? 'rc_participant' : 'participant',
    SESSION: 'session',
    LIVE: 'live_url',
    LIVE_STATUS: 'live_status',
  },
};

export const LIFF_ID = process.env.GATSBY_LIFF_ID;
