import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow, take, compact, shuffle, toPairs, groupBy, map } from 'lodash/fp';
import { actions, PickupSessions } from '@/store/pickup/slice';
import * as pickupSelector from '@/store/pickup/selectors';

import { Session } from '@/types/session';
import { FetchStatus } from '@/types/common';
import { getPickupSessions } from '@/queries/session/pickupsession.queries';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { formatLocale } from '@/utils/utility';

export const usePickupStore = () => {
  const dispath = useDispatch();

  const day1PickupSessions = useSelector(pickupSelector.getDay1PickupSessions);
  const day2PickupSessions = useSelector(pickupSelector.getDay2PickupSessions);
  const pickupFetchingStatus = useSelector(pickupSelector.getPickupFetchingStatus);

  const setDay1PickupSessions = (newState: PickupSessions) => {
    dispath(actions.setDay1PickupSessions(newState));
  };
  const setDay2PickupSessions = (newState: PickupSessions) => {
    dispath(actions.setDay2PickupSessions(newState));
  };
  const setPickupFetchingStatus = (newState: FetchStatus) => {
    dispath(actions.setPickupFetchingStatus(newState));
  };

  return {
    day1PickupSessions,
    day2PickupSessions,
    pickupFetchingStatus,
    setDay1PickupSessions,
    setDay2PickupSessions,
    setPickupFetchingStatus,
  };
};

export const usePickupSessions = () => {
  const {
    day1PickupSessions,
    day2PickupSessions,
    pickupFetchingStatus,
    setDay1PickupSessions,
    setDay2PickupSessions,
    setPickupFetchingStatus,
  } = usePickupStore();
  const pickupSessions = getPickupSessions();
  const { languages, language } = useI18next();
  const formattedLocales = languages.map(formatLocale);

  useEffect(() => {
    if (pickupFetchingStatus === FetchStatus.INITIAL) {
      const day1Sessions = flow(
        groupBy('postId'),
        toPairs,
        map(([_key, value]) => {
          return {
            [formattedLocales[0] as string]: value.find((o) => o.locale === formattedLocales[0]),
            [formattedLocales[1] as string]: value.find((o) => o.locale === formattedLocales[1]),
            [formattedLocales[2] as string]: value.find((o) => o.locale === formattedLocales[2]),
          };
        }),
        shuffle,
        take(2)
      )(pickupSessions.day1);

      const day2Sessions = flow(
        groupBy('postId'),
        toPairs,
        map(([_key, value]) => {
          return {
            [formattedLocales[0] as string]: value.find((o) => o.locale === formattedLocales[0]),
            [formattedLocales[1] as string]: value.find((o) => o.locale === formattedLocales[1]),
            [formattedLocales[2] as string]: value.find((o) => o.locale === formattedLocales[2]),
          };
        }),
        shuffle,
        take(2)
      )(pickupSessions.day2);

      setDay1PickupSessions(day1Sessions);
      setDay2PickupSessions(day2Sessions);
      setPickupFetchingStatus(FetchStatus.SUCCESS);
    }
  }, [pickupFetchingStatus]);

  const currentLocaleDay1PickupSessions: Session[] = useMemo(() => {
    if (!day1PickupSessions.length) return [];

    const formattedLocale = formatLocale(language) as string;

    return flow(
      map((allLocaleSession: any) => {
        if (allLocaleSession[formattedLocale] !== undefined) {
          return allLocaleSession[formattedLocale];
        }

        return null;
      }),
      compact
    )(day1PickupSessions);
  }, [day1PickupSessions, language]);

  const currentLocaleDay2PickupSessions: Session[] = useMemo(() => {
    if (!day2PickupSessions.length) return [];

    const formattedLocale = formatLocale(language) as string;

    return flow(
      map((allLocaleSession: any) => {
        if (allLocaleSession[formattedLocale] !== undefined) {
          return allLocaleSession[formattedLocale];
        }

        return null;
      }),
      compact
    )(day2PickupSessions);
  }, [day2PickupSessions, language]);

  return {
    day1PickupSessions: currentLocaleDay1PickupSessions,
    day2PickupSessions: currentLocaleDay2PickupSessions,
  };
};
