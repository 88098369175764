import React, { FC, useState } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';

import { usePickupSessions } from '@/hooks/pickup';
import { formatCategories, formatSpeakersName } from '@/commons/formatter';
import { useTranslation } from 'react-i18next';

const Session: FC = () => {
  const { t } = useTranslation();

  const [pickup1AvatarEnabled, setPickup1AvatarEnabled] = useState<boolean>(false);
  const [pickup2AvatarEnabled, setPickup2AvatarEnabled] = useState<boolean>(false);
  const [pickup3AvatarEnabled, setPickup3AvatarEnabled] = useState<boolean>(false);
  const [pickup4AvatarEnabled, setPickup4AvatarEnabled] = useState<boolean>(false);

  const { day1PickupSessions, day2PickupSessions } = usePickupSessions();

  return (
    <>
      <div className="pick_up">
        <div className="pick_up_title">
          <h4 dangerouslySetInnerHTML={{ __html: t('top.pick_up.day1') }} />
          <div dangerouslySetInnerHTML={{ __html: t('top.pick_up.day1_tag') }} />
        </div>
        <div className="pick_up_list">
          {day1PickupSessions.length ? (
            <>
              {day1PickupSessions[0] && (
                <Link to={`/sessions/${day1PickupSessions[0].postId}`} className="pick_up_item" state={{ modal: true }}>
                  <p className="category">{formatCategories(day1PickupSessions[0].categories)}</p>
                  <h5>{day1PickupSessions[0].title}</h5>
                  <p className="speaker">{formatSpeakersName(day1PickupSessions[0].speakers)}</p>
                  <div className="character">
                    {day1PickupSessions[0].avatar_static && day1PickupSessions[0].avatar_static.length > 0 && (
                      <>
                        {!pickup1AvatarEnabled && (
                          <img src={day1PickupSessions[0].avatar_static[0].fullUrl} alt="character" />
                        )}
                        <img
                          src={day1PickupSessions[0].avatar[0].fullUrl}
                          id="pickup_avatar1"
                          style={{ display: pickup1AvatarEnabled ? 'inline' : 'none' }}
                          onLoad={() => {
                            setTimeout(() => {
                              setPickup1AvatarEnabled(true);
                            }, 1000);
                          }}
                          alt="character"
                        />
                      </>
                    )}
                  </div>
                </Link>
              )}
              {day1PickupSessions[1] && (
                <Link to={`/sessions/${day1PickupSessions[1].postId}`} className="pick_up_item" state={{ modal: true }}>
                  <p className="category">{formatCategories(day1PickupSessions[1].categories)}</p>
                  <h5>{day1PickupSessions[1].title}</h5>
                  <p className="speaker">{formatSpeakersName(day1PickupSessions[1].speakers)}</p>
                  <div className="character">
                    {day1PickupSessions[1].avatar_static && day1PickupSessions[1].avatar_static.length > 0 && (
                      <>
                        {!pickup2AvatarEnabled && (
                          <img src={day1PickupSessions[1].avatar_static[0].fullUrl} alt="character" />
                        )}
                        <img
                          src={day1PickupSessions[1].avatar[0].fullUrl}
                          id="pickup_avatar1"
                          style={{ display: pickup2AvatarEnabled ? 'inline' : 'none' }}
                          onLoad={() => {
                            setTimeout(() => {
                              setPickup2AvatarEnabled(true);
                            }, 2000);
                          }}
                          alt="character"
                        />
                      </>
                    )}
                  </div>
                </Link>
              )}
            </>
          ) : (
            <> </>
          )}
        </div>
      </div>
      <div className="pick_up">
        <div className="pick_up_title">
          <h4 dangerouslySetInnerHTML={{ __html: t('top.pick_up.day2') }} />
          <div dangerouslySetInnerHTML={{ __html: t('top.pick_up.day2_tag') }} />
        </div>
        <div className="pick_up_list">
          {day2PickupSessions.length ? (
            <>
              {day2PickupSessions[0] && (
                <Link to={`/sessions/${day2PickupSessions[0].postId}`} className="pick_up_item" state={{ modal: true }}>
                  <p className="category">{formatCategories(day2PickupSessions[0].categories)}</p>
                  <h5>{day2PickupSessions[0].title}</h5>
                  <p className="speaker">{formatSpeakersName(day2PickupSessions[0].speakers)}</p>
                  <div className="character">
                    {day2PickupSessions[0].avatar_static && day2PickupSessions[0].avatar_static.length > 0 && (
                      <>
                        {!pickup3AvatarEnabled && (
                          <img src={day2PickupSessions[0].avatar_static[0].fullUrl} alt="character" />
                        )}
                        <img
                          src={day2PickupSessions[0].avatar[0].fullUrl}
                          id="pickup_avatar1"
                          style={{ display: pickup3AvatarEnabled ? 'inline' : 'none' }}
                          onLoad={() => {
                            setTimeout(() => {
                              setPickup3AvatarEnabled(true);
                            }, 3000);
                          }}
                          alt="character"
                        />
                      </>
                    )}
                  </div>
                </Link>
              )}
              {day2PickupSessions[1] && (
                <Link to={`/sessions/${day2PickupSessions[1].postId}`} className="pick_up_item" state={{ modal: true }}>
                  <p className="category">{formatCategories(day2PickupSessions[1].categories)}</p>
                  <h5>{day2PickupSessions[1].title}</h5>
                  <p className="speaker">{formatSpeakersName(day2PickupSessions[1].speakers)}</p>
                  <div className="character">
                    {day2PickupSessions[1].avatar_static && day2PickupSessions[1].avatar_static.length > 0 && (
                      <>
                        {!pickup4AvatarEnabled && (
                          <img src={day2PickupSessions[1].avatar_static[0].fullUrl} alt="character" />
                        )}
                        <img
                          src={day2PickupSessions[1].avatar[0].fullUrl}
                          id="pickup_avatar1"
                          style={{ display: pickup4AvatarEnabled ? 'inline' : 'none' }}
                          onLoad={() => {
                            setTimeout(() => {
                              setPickup4AvatarEnabled(true);
                            }, 4000);
                          }}
                          alt="character"
                        />
                      </>
                    )}
                  </div>
                </Link>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Session;
