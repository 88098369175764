import { LiveUrls } from '@/types/live';
import { useStaticQuery, graphql } from 'gatsby';

type LiveUrlsQueryType = {
  landPressLiveUrl: LiveUrls;
};
export const useStaticLiveUrls = () => {
  const { landPressLiveUrl } = useStaticQuery<LiveUrlsQueryType>(graphql`
    query getLiveUrls {
      landPressLiveUrl {
        track1_en
        track1_ja
        track1_ko
        track2_en
        track2_ja
        track2_ko
        track3_en
        track3_ja
        track3_ko
        track4_ja
        track4_ko
        track4_en
      }
    }
  `);

  return landPressLiveUrl;
};
