import React, { FC, useEffect, useState } from 'react';
import { fetchLiveUrls, fetchLiveInfo } from '@/networks/live';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { LiveUrls, LiveInfo, VideoData, LiveStatus } from '@/types/live';
import LivePlayer from '@/components/LivePlayer';
import { useStaticLiveUrls } from '@/queries/live/live.queries.ts';
import { withAssetPrefix } from 'gatsby';

type Props = {
  track: number;
};

const LiveArea: FC<Props> = (props) => {
  const { track } = props;

  const [liveUrls, setLiveUrls] = useState<LiveUrls>();
  const [isError, setIsError] = useState<boolean>(false);
  const { language, t } = useI18next();
  const [videoInfos, setVideoInfos] = useState<{
    en: LiveInfo;
    ja: LiveInfo;
    ko: LiveInfo;
  }>();
  const [videoData, setVideoData] = useState<VideoData>();
  const staticLiveUrls = useStaticLiveUrls();

  const [videoLanguage, setVideoLanguage] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchLiveUrls();

        setLiveUrls(data);
      } catch {
        setLiveUrls(staticLiveUrls);
      }
    })();
  }, [staticLiveUrls]);

  useEffect(() => {
    if (liveUrls) {
      (async () => {
        try {
          const data = await Promise.all([
            fetchLiveInfo(liveUrls[`track${track}_en`]),
            fetchLiveInfo(liveUrls[`track${track}_ja`]),
            fetchLiveInfo(liveUrls[`track${track}_ko`]),
          ]);

          setVideoInfos({
            en: data[0],
            ja: data[1],
            ko: data[2],
          });

          setVideoLanguage(language);
        } catch {
          setIsError(true);
        }
      })();
    }
  }, [liveUrls, language, track]);

  useEffect(() => {
    return () => setIsError(false);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const languageName = (() => {
        if (language === 'ja') return '"Japanese"';
        if (language === 'ko') return '"Korean"';
        return '"English"';
      })();
      localStorage.setItem('lc.Video.UI.Caption-name', languageName);
    }
  }, [language]);

  const handleChangeLanguage = (lang: string) => {
    if (lang === 'English') {
      setVideoLanguage('en');
    }
    if (lang === 'Japanese') {
      setVideoLanguage('ja');
    }
    if (lang === 'Korean') {
      setVideoLanguage('ko');
    }
  };

  const handleVideoEnded = () => {
    setIsError(true);
  };

  useEffect(() => {
    if (!videoLanguage || !videoInfos) {
      return;
    }
    const currentLanguageVideoInfo = videoInfos[videoLanguage] as LiveInfo;

    if (currentLanguageVideoInfo.status === 404) {
      setIsError(true);
      return;
    }

    if (currentLanguageVideoInfo.item.liveStatus !== LiveStatus.LIVE) {
      setIsError(true);
      return;
    }

    setVideoData({
      '360P': {
        url: currentLanguageVideoInfo.liveHLSURLs[360],
        thumbnail: withAssetPrefix('/video_thumbnail.png'),
      },
      '480P': {
        url: currentLanguageVideoInfo.liveHLSURLs[480],
        thumbnail: withAssetPrefix('/video_thumbnail.png'),
      },
      '720P': {
        url: currentLanguageVideoInfo.liveHLSURLs[720],
        thumbnail: withAssetPrefix('/video_thumbnail.png'),
      },
      '1080P': {
        url: currentLanguageVideoInfo.liveHLSURLs[1080],
        thumbnail: withAssetPrefix('/video_thumbnail.png'),
      },
    });
  }, [videoInfos, videoLanguage]);

  if (isError) {
    return (
      <>
        <div className="video_area"></div>
        <div className="video_status_area type_error">
          <div className="icon_error" />
          <p className="msg">
            <span className="text_gradient">{t('live.error_text')}</span>
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="video_area">
      {videoData && (
        <LivePlayer videoInfo={videoData} onChangeLanguage={handleChangeLanguage} onEnded={handleVideoEnded} />
      )}
    </div>
  );
};

export default LiveArea;
