import React, { FC } from 'react';
import Modal from '@/components/commons/Modal';
import { LoginContent } from './PopupContent';

type Props = {
  visible: boolean;
  onClose?: () => void;
};

const LoginModal: FC<Props> = (props) => {
  const { visible, onClose } = props;

  return (
    <>
      <Modal
        className="type_bg"
        visible={visible}
        onClose={() => {
          onClose && onClose();
        }}
      >
        <LoginContent />
      </Modal>
    </>
  );
};

export default LoginModal;
