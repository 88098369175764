import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { useTimeout, useWindowFocus } from './common';
import { getDay1Time, getDay2Time } from '@/utils/utility';

export const useMain = () => {
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();
  const DEFAULT_DAY1_TIME = getDay1Time();
  const DEFAULT_DAY2_TIME = getDay2Time();
  const DAY1_START_TIME = DEFAULT_DAY1_TIME.toUTC().toMillis();
  const DAY2_START_TIME = DEFAULT_DAY2_TIME.toUTC().toMillis();
  const DAY1_1230 = DEFAULT_DAY1_TIME.set({ hour: 12, minute: 30 }).toUTC().toMillis();
  const DAY1_1340 = DEFAULT_DAY1_TIME.set({ hour: 13, minute: 40 }).toUTC().toMillis();
  const DAY2_1300 = DEFAULT_DAY2_TIME.set({ hour: 13, minute: 0, second: 0, millisecond: 0 }).toUTC().toMillis();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const durationMilis = useMemo(() => {
    if (DAY1_START_TIME <= currentTimeToUTC && currentTimeToUTC < DAY1_1230) {
      return DAY1_1230 - currentTimeToUTC;
    }

    if (DAY1_1230 <= currentTimeToUTC && currentTimeToUTC < DAY1_1340) {
      return DAY1_1340 - currentTimeToUTC;
    }

    if (DAY2_START_TIME <= currentTimeToUTC && currentTimeToUTC < DAY2_1300) {
      return DAY2_1300 - currentTimeToUTC;
    }

    return 0;
  }, [isUpdated]);

  useWindowFocus(() => {
    setIsUpdated(!isUpdated);
  });

  useTimeout(() => setIsUpdated(!isUpdated), durationMilis);
};
