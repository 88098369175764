import React from 'react';

import { Category, Speaker } from '@/types/session';
import { TimeType } from '@/types/time';
import BeforeKeynote from '@/components/MainTop/BeforeKeynote';
import LiveOfBeforeKeynote from '@/components/MainTop/LiveOfBeforeKeynote';
import OpeningKeynote from '@/components/MainTop/OpeningKeynote';
import EndedAllTrack from '@/components/MainTop/EndedAllTrack';
import LiveCardList from '@/components/MainTop/LiveCardList';
import BeforeLiveOfDayTwo from '@/components/MainTop/BeforeLiveOfDayTwo';

export const formatCategories = (categories: Category[]) => {
  return categories.map((category) => category.name).join(', ');
};

export const formatSpeakersName = (speakers: Speaker[]) => {
  return speakers.map((speaker) => speaker.name).join(' / ');
};

export const formatMainTopComponent = (type: TimeType) => {
  const component = {
    [TimeType.DAY1_BEFORE1230]: <BeforeKeynote />,
    [TimeType.DAY1_1230TO1259]: <LiveOfBeforeKeynote />,
    [TimeType.DAY1_1300TO1339]: <OpeningKeynote />,
    [TimeType.DAY1_AFTER1340]: <LiveCardList />,
    [TimeType.DAY1_ENDED_TRACK]: <EndedAllTrack />,
    [TimeType.DAY2_BEFORE1300]: <BeforeLiveOfDayTwo />,
    [TimeType.DAY2_AFTER1300]: <LiveCardList />,
    [TimeType.DAY2_ENDED_TRACK]: <EndedAllTrack />,
  };

  return component ? component[type] : <></>;
};
