type EventParams = {
  category: string;
  action: string;
  label: string;
  value?: any;
};

export const sendLAEvent = (params: EventParams) => {
  if (window._trm) {
    window._trm('send', 'event', {
      eventCategory: params.category,
      eventAction: params.action,
      eventLabel: params.label,
      eventValue: params.value,
    });
  }
};

export const sendGAEvent = (params: EventParams) => {
  if (window.gtag) {
    window.gtag('event', params.action, {
      event_category: params.category,
      event_label: params.label,
      value: params.value,
    });
  }
};

export const sendEvent = (param: EventParams) => {
  sendLAEvent(param);
  sendGAEvent(param);
};
