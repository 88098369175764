import { Session, TrackDay } from '@/types/session';
import { filteredDay } from '@/utils/utility';
import { useStaticQuery, graphql } from 'gatsby';

type SessionsQueryType = {
  allLandPressSession: {
    nodes: Array<Session>;
  };
};

export const getPickupSessions = () => {
  const {
    allLandPressSession: { nodes: data },
  } = useStaticQuery<SessionsQueryType>(graphql`
    {
      allLandPressSession(filter: { is_pickup: { eq: true } }) {
        nodes {
          locale
          categories {
            postId
            name
          }
          postId
          speakers {
            name
          }
          title
          track
          avatar {
            fullUrl
          }
          avatar_static {
            fullUrl
          }
        }
      }
    }
  `);

  return {
    day1: data.filter((session) => filteredDay(session.track) === TrackDay.day1),
    day2: data.filter((session) => filteredDay(session.track) === TrackDay.day2),
  };
};
