import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { withAssetPrefix } from 'gatsby';

type Props = {
  title?: string;
  description?: string;
  image?: string;
};

const SEO: FC<Props> = (props) => {
  const { title = 'LINE DEVELOPER DAY 2021', description, image } = props;

  const { language, t } = useI18next();

  const metaTitle = title;
  const metaDescription = description || t('ogp.common.description');
  const metaImage = image || withAssetPrefix('/og_image.png');

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keyword`,
          content: 'LINE DEVELOPER DAY, LINE DEV DAY, LINE DEVDAY, LINE Tech Conference',
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ]}
    ></Helmet>
  );
};

export default SEO;
