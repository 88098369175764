import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Lottie from 'react-lottie';
import { throttle } from 'lodash';

import { useSessions } from '@/hooks/session';
import SessionCard from '@/components/SessionCard';
import { useTranslation } from 'react-i18next';
import NeedLoginModal from '@/components/commons/NeedLoginModal';
import { sendEvent } from '@/commons/analytics';
import PickupSessions from './PickupSessions';

import lottie50Plus from '@/assets/img/lottie/lottie_50plus.json';

const Session: FC = () => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { categories, filteredSessions, filteredCategoryList, sessionLength, onChangeCategoryCheckBox } = useSessions();
  const [isClickSessionFilterWithMobile, setIsClickSessionFilterWithMobile] = useState<boolean>(false);

  const [showNeedLoginModal, setShowNeedLoginModal] = useState(false);

  const [text50Enabled, setText50Enabled] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const windowHeight = window.innerHeight;
      const textLearnElement = document.querySelector('#text_learn');
      const text80plusElement = document.querySelector('#text_80plus');

      const handler = throttle(() => {
        if (textLearnElement && textLearnElement.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.24)) {
          textLearnElement.classList.add('is_active');
        }
        if (
          text80plusElement &&
          text80plusElement.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.268)
        ) {
          setText50Enabled(true);
        }
      }, 100);

      window.addEventListener('scroll', handler);

      // window.addEventListener('resize', init);
      return () => window.removeEventListener('scroll', handler);
    }
  });

  return (
    <>
      <div className="session">
        <h2>
          <span id="text_learn">Learn from</span>
          <span id="text_80plus" className={classnames({ is_active: text50Enabled })}>
            over&nbsp;
            <span id="lottie_50plus">
              <Lottie
                style={{ overflow: 'visible' }}
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: text50Enabled ? lottie50Plus : null,
                }}
              />
            </span>
            &nbsp;sessions
          </span>
        </h2>
        <h3 id="pickup">{t('top.pick_up.sessions')}</h3>
        <PickupSessions />
        <h3 id="sessions">
          {t('top.schedule.sessions')}
          <button
            className="btn_schedule"
            onClick={() => {
              sendEvent({ category: 'page_top', action: 'click_timeschedule', label: 'timetable_button' });
              navigate('/timeschedule', { state: { modal: true } });
            }}
          >
            {t('top.schedule.time_table')}
          </button>
        </h3>
        <div className={classnames('session_filter', [isClickSessionFilterWithMobile && 'is_active'])}>
          <div>
            <label htmlFor="session_filter0" className="ck_filter">
              <input
                type="checkbox"
                id="session_filter0"
                className="blind"
                checked={!filteredCategoryList.length}
                onChange={(e) => onChangeCategoryCheckBox(e.target.checked, 'ALL')}
              />
              <span> ALL </span>
            </label>
            {categories.map((category) => (
              <label key={category.postId} htmlFor={`session_filter${category.postId}`} className="ck_filter">
                <input
                  type="checkbox"
                  id={`session_filter${category.postId}`}
                  className="blind"
                  checked={filteredCategoryList.includes(category.name)}
                  onClick={() =>
                    sendEvent({ category: 'page_top', action: 'click_category', label: `${category.name}` })
                  }
                  onChange={(e) => {
                    onChangeCategoryCheckBox(e.target.checked, category.name);
                  }}
                />
                <span> {category.name} </span>
              </label>
            ))}
          </div>
          {/* 클릭시 session_filter에 is_active 클래스 추가 */}
          <button
            className="btn_filter_toggle"
            onClick={() => setIsClickSessionFilterWithMobile(!isClickSessionFilterWithMobile)}
          >
            <span className="blind">open</span>
          </button>
        </div>
        <div className="session_result">
          <div>
            <p dangerouslySetInnerHTML={{ __html: t('top.schedule.session_count', { count: sessionLength }) }} />
          </div>
          <ul className="session_list">
            {filteredSessions.map((session) => (
              <SessionCard key={session.postId} session={session} />
            ))}
          </ul>
        </div>
      </div>
      <NeedLoginModal
        className="bookmark_popup"
        visible={showNeedLoginModal}
        onClose={() => setShowNeedLoginModal(false)}
      />
    </>
  );
};

export default Session;
