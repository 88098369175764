import { LIFF_ID } from '@/commons/config';

const liff = typeof window !== 'undefined' && window.liff ? window.liff : null;

export const initLiff = () => {
  if (!liff) {
    return;
  }
  liff.init({ liffId: LIFF_ID });
};

export default liff;
