import React from 'react';

import Footer from '@/components/commons/Footer';
import Header from '@/components/commons/Header';

const BasicLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <>
      <Header />
      <main id="container" className="container">
        {children}
      </main>
      <Footer />
    </>
  );
};

export default BasicLayout;
