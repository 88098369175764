import React, { FC } from 'react';

import MainTitleCTO from '@/assets/img/main_title_cto@2x.png';
import MainTitleCTOMobile from '@/assets/img/mobile/main_title@2x.png';
import MainBgLive from '@/assets/img/main_bg_live@2x.png';
import MainBgLiveMobile from '@/assets/img/mobile/main_bg_live@2x.png';
import { getDay } from '@/utils/utility';

const EndedAllTrack: FC = () => {
  return (
    <div className="main_top type_live">
      <div className="cto_area">
        <h2 className="title">
          <img src={MainTitleCTO} className="pc" alt="LINE DEVELOPER DAY 2021" />
          <img src={MainTitleCTOMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
        </h2>
        <h3 className="sub_title">
          <span className="text_gradient">{`DAY ${getDay()}`}</span>
        </h3>
        <p className="text">All sessions have ended.</p>
        <div className="visual_img">
          <img src={MainBgLive} className="pc" alt="" />
          <img src={MainBgLiveMobile} className="mobile" alt="" />
        </div>
      </div>
      <a href="#about">
        <div className="desc_scroll">
          <span>Scroll</span>
        </div>
      </a>
    </div>
  );
};

export default EndedAllTrack;
