import { DateTime } from 'luxon';
import { sortBy, minBy, maxBy } from 'lodash';

import { Session, Track } from '@/types/session';
import { filteredTrack, formatLiveUrl, formatTime } from '@/utils/utility';
import { useCallback, useMemo } from 'react';
import { getSessions } from '@/queries/session/session.queries';
import { useTimezoneSuffix } from './common';

export const useLivePage = (track: number) => {
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();

  const sessions = getSessions();

  const session = useMemo(() => {
    let filteredSession: Session[];

    if (track === 1) {
      filteredSession = sessions.filter(
        (session) => session.track === Track.DAY1_TRACK1 || session.track === Track.DAY2_TRACK1
      );
    } else if (track === 2) {
      filteredSession = sessions.filter(
        (session) => session.track === Track.DAY1_TRACK2 || session.track === Track.DAY2_TRACK2
      );
    } else if (track === 3) {
      filteredSession = sessions.filter(
        (session) => session.track === Track.DAY1_TRACK3 || session.track === Track.DAY2_TRACK3
      );
    } else {
      filteredSession = sessions.filter(
        (session) => session.track === Track.DAY1_TRACK4 || session.track === Track.DAY2_TRACK4
      );
    }

    const currentSession = filteredSession.find(
      (session) =>
        DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis() < currentTimeToUTC &&
        currentTimeToUTC <= DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis()
    );

    if (!currentSession) {
      const currentTime = DateTime.fromJSDate(new Date());
      const todaySessions = filteredSession.filter((s) =>
        DateTime.fromISO(s.start_at, { setZone: true }).hasSame(currentTime, 'day')
      );
      const lastSessionOfToday = maxBy(todaySessions, 'end_at');

      if (!lastSessionOfToday) {
        return;
      }

      const isAfter1230 =
        currentTimeToUTC >
        currentTime.setZone('JST').set({ hour: 12, minute: 30, second: 0, millisecond: 0 }).toUTC().toMillis();
      const isBefore1340 =
        currentTimeToUTC <
        currentTime.setZone('JST').set({ hour: 13, minute: 40, second: 0, millisecond: 0 }).toUTC().toMillis();
      const isEndedSessionOfTrack =
        currentTimeToUTC <
        DateTime.fromJSDate(new Date(lastSessionOfToday.end_at))
          .plus({
            minutes: 5,
          })
          .toUTC()
          .toMillis();

      if (isAfter1230 && isBefore1340) {
        return minBy(todaySessions, 'start_at');
      } else if (!isBefore1340 && isEndedSessionOfTrack) {
        return maxBy(todaySessions, 'end_at');
      }
    }

    return currentSession;
  }, [sessions]);

  const durationTimeToMillis = useMemo(() => {
    if (!session) return 0;

    const duration = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis() - currentTimeToUTC;
    const lastDuration =
      DateTime.fromJSDate(new Date(session.end_at)).plus({ minutes: 5 }).toUTC().toMillis() - currentTimeToUTC;

    if (lastDuration < 0) {
      return 0;
    }

    return duration > 0 ? duration : lastDuration;
  }, [session, currentTimeToUTC]);
  const nextSessions = useMemo(() => {
    if (!session) {
      return [];
    }

    const list = sortBy(sessions, ['start_at', 'track']);

    return list
      .filter((v) => {
        return (
          DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis() <=
          DateTime.fromJSDate(new Date(v.start_at)).toUTC().toMillis()
        );
      })
      .slice(0, 4);
  }, [sessions]);
  const metaDescription = useMemo(() => {
    if (!session) {
      return '';
    }
    const speakersName = session.speakers.map((speaker) => speaker.name).join(', ');
    return `${session.title} | ${speakersName} / ${session.speakers[0]?.company}`;
  }, [session]);

  const metaImage = useMemo(() => {
    if (!session) {
      return '';
    }

    return !!session.og_image.length ? session.og_image[0].fullUrl : undefined;
  }, [session]);

  const isExistSession = useCallback(
    (session: Session) => sessions.find((v) => v.postId === session.postId),
    [sessions]
  );

  return {
    metaDescription,
    metaImage,
    session,
    nextSessions,
    durationTimeToMillis,
    isExistSession,
  };
};

export const useLiveCardList = () => {
  const currentTimeToUTC = DateTime.fromJSDate(new Date()).toUTC().toMillis();
  const sessions = getSessions();
  const trackOneLive = useMemo(() => {
    return sessions
      .filter((session) => session.track === Track.DAY1_TRACK1 || session.track === Track.DAY2_TRACK1)
      .find((session) => {
        const startTimeToUTC = DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis();
        const endTimeToUTC = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis();

        return startTimeToUTC <= currentTimeToUTC && currentTimeToUTC < endTimeToUTC;
      });
  }, [sessions, currentTimeToUTC]);

  const trackTwoLive = useMemo(() => {
    return sessions
      .filter((session) => session.track === Track.DAY1_TRACK2 || session.track === Track.DAY2_TRACK2)
      .find((session) => {
        const startTimeToUTC = DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis();
        const endTimeToUTC = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis();

        return startTimeToUTC <= currentTimeToUTC && currentTimeToUTC < endTimeToUTC;
      });
  }, [sessions, currentTimeToUTC]);

  const trackThreeLive = useMemo(() => {
    return sessions
      .filter((session) => session.track === Track.DAY1_TRACK3 || session.track === Track.DAY2_TRACK3)
      .find((session) => {
        const startTimeToUTC = DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis();
        const endTimeToUTC = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis();

        return startTimeToUTC <= currentTimeToUTC && currentTimeToUTC < endTimeToUTC;
      });
  }, [sessions, currentTimeToUTC]);

  const trackFourLive = useMemo(() => {
    return sessions
      .filter((session) => session.track === Track.DAY1_TRACK4 || session.track === Track.DAY2_TRACK4)
      .find((session) => {
        const startTimeToUTC = DateTime.fromJSDate(new Date(session.start_at)).toUTC().toMillis();
        const endTimeToUTC = DateTime.fromJSDate(new Date(session.end_at)).toUTC().toMillis();

        return startTimeToUTC <= currentTimeToUTC && currentTimeToUTC < endTimeToUTC;
      });
  }, [sessions, currentTimeToUTC]);

  return {
    trackOneLive,
    trackTwoLive,
    trackThreeLive,
    trackFourLive,
  };
};

export const useLiveCard = (session: Session) => {
  const timezoneSuffix = useTimezoneSuffix();
  const categories = session.categories.map((category) => category.name).join(', ');
  const title = session.title;
  const track = `Track ${filteredTrack(session.track)}`;
  const durationTime = `${formatTime(session.start_at)}-${formatTime(session.end_at)} ${timezoneSuffix}`;
  const url = formatLiveUrl(session.track);
  const speakers = session.speakers.map((speaker) => speaker.name).join(', ');
  const avatar = session.avatar[0] ? session.avatar[0].fullUrl : '';

  return {
    categories,
    title,
    track,
    durationTime,
    speakers,
    avatar,
    url,
  };
};

export const useCardListOfBeforeLiveDay2 = () => {
  const sessions = getSessions();

  const trackOneLive = useMemo(() => {
    return minBy(
      sessions.filter((session) => session.track === Track.DAY2_TRACK1),
      'start_at'
    );
  }, [sessions]);

  const trackTwoLive = useMemo(() => {
    return minBy(
      sessions.filter((session) => session.track === Track.DAY2_TRACK2),
      'start_at'
    );
  }, [sessions]);

  const trackThreeLive = useMemo(() => {
    return minBy(
      sessions.filter((session) => session.track === Track.DAY2_TRACK3),
      'start_at'
    );
  }, [sessions]);

  const trackFourLive = useMemo(() => {
    return minBy(
      sessions.filter((session) => session.track === Track.DAY2_TRACK4),
      'start_at'
    );
  }, [sessions]);

  return {
    trackOneLive,
    trackTwoLive,
    trackThreeLive,
    trackFourLive,
  };
};
