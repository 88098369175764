import React, { FC } from 'react';

import MainTitleCTOMobile from '@/assets/img/mobile/main_title@2x.png';
import LiveArea from '@/components/commons/LiveArea';
import { useTimezoneSuffix } from '@/hooks/common';

const LiveOfBeforeKeynote: FC = () => {
  const timezone = useTimezoneSuffix();

  return (
    <div className="main_top type_live">
      <div className="cto_area type_video">
        {/* [D] video_area */}
        <div className="cto_video">
          <LiveArea track={1} />
        </div>
        <div className="video_info">
          <h2 className="mobile_title">
            <img src={MainTitleCTOMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
          </h2>
          <h3 className="info_title">
            <span className="text_gradient">OPENING KEYNOTE</span>
            <span className="time">{`13:00 ${timezone} START`}</span>
          </h3>
        </div>
      </div>
      <a href="#about">
        <div className="desc_scroll">
          <span>Scroll</span>
        </div>
      </a>
    </div>
  );
};

export default LiveOfBeforeKeynote;
