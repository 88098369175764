import { AxiosError } from 'axios';

export enum ErrorCode {
  REQUIRE_VALUE = 'REQUIRE_VALUE',
  EXISTS_LOCALE_ITEM = 'EXISTS_LOCALE_ITEM',
  NOT_FOUND_LOCALE = 'NOT_FOUND_LOCALE',
  NOT_FOUND_PRIMARY_ITEM = 'NOT_FOUND_PRIMARY_ITEM',
  MUST_ONE_ITEM = 'MUST_ONE_ITEM',
  NOT_FOUND_PROJECT = 'NOT_FOUND_PROJECT',
  NOT_FOUND_COLLECTION = 'NOT_FOUND_COLLECTION',
  NOT_FOUND_JOIN_COLLECTION = 'NOT_FOUND_JOIN_COLLECTION',
  NOT_FOUND_POST_ID = 'NOT_FOUND_POST_ID',
  NOT_FOUND_FIELD = 'NOT_FOUND_FIELD',
  NOT_FOUND_RELATION = 'NOT_FOUND_RELATION',
  NOT_FOUND_ITEM = 'NOT_FOUND_ITEM',
  NOT_FOUND_LANDPRESS_PROJECT = 'NOT_FOUND_LANDPRESS_PROJECT',
  NOT_FOUND_API_KEY = 'NOT_FOUND_API_KEY',
  INVALID_API_TOKEN = 'INVALID_API_TOKEN',
  VALIDATION_ONE_TO_ONE = 'VALIDATION_ONE_TO_ONE',
  VALIDATION_UID_DUPLICATE = 'VALIDATION_UID_DUPLICATE',
  VALIDATION_FIELD_VALUE_TYPE = 'VALIDATION_FIELD_VALUE_TYPE',
  DATABASE_EXCEPTION = 'DATABASE_EXCEPTION',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_SINGLE_TYPE_COLLECTION = 'INVALID_SINGLE_TYPE_COLLECTION',
}

export enum ErrorMessage {
  __DEFAULT__ = `Your request is not available at now. Try again later.`,
}

export type ErrorResponse = {
  error: string;
  message: ErrorCode;
  statusCode: number;
};

export type ApiError = AxiosError<ErrorResponse>;
