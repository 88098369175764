import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as registrationSelector from '@/store/registration/selectors';
import { useAuthStore } from './auth';
import { actions } from '@/store/registration/slice';

export const useRegistrationModal = () => {
  const dispath = useDispatch();
  const { isInitialized, isRegistered, isShowPopup } = useAuthStore();
  const [isShowLoginModal, setIsShowLoginModal] = useState<boolean>(false);

  const isShow = useSelector(registrationSelector.getIsOpen);

  const setIsShow = (newState: boolean) => {
    dispath(actions.setIsOpen(newState));
  };

  const onClose = () => setIsShow(false);
  const handleOpenLoginModal = () => {
    setIsShowLoginModal(true);
  };
  const handleCloseLoginModal = () => setIsShowLoginModal(false);

  useEffect(() => {
    const isRedirected = !!window.localStorage.getItem('isRedirected');

    if (isInitialized && !isRegistered && (isShowPopup || !!isRedirected)) {
      setIsShow(true);
      handleCloseLoginModal();
    }
  }, [isRegistered, isInitialized, isShowPopup]);

  return { isShow, isShowLoginModal, handleOpenLoginModal, handleCloseLoginModal, onClose };
};
