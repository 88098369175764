import React, { FC } from 'react';

import { useCardListOfBeforeLiveDay2 } from '@/hooks/live';
import MainTitleLive from '@/assets/img/main_title_live@2x.png';
import MainTitleLiveMobile from '@/assets/img/mobile/main_title@2x.png';
import LiveCard from '../LiveCardList/LiveCard';

const BeforeLiveOfDayTwo: FC = () => {
  const { trackOneLive, trackTwoLive, trackThreeLive, trackFourLive } = useCardListOfBeforeLiveDay2();

  return (
    <div className="main_top type_live">
      <div className="live_area">
        <div className="live_title">
          <h2 className="title">
            <img src={MainTitleLive} className="pc" alt="LINE DEVELOPER DAY 2021" />
            <img src={MainTitleLiveMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
            <span className="text_gradient">Day 2</span>
          </h2>
          <p className="desc">
            <span>NOVEMBER 10-11</span>
            <span className="font_en">@</span>
            <span>ONLINE</span>
          </p>
        </div>
        <div className="live_list">
          {trackOneLive && <LiveCard session={trackOneLive} isLive={false} />}
          {trackTwoLive && <LiveCard session={trackTwoLive} isLive={false} />}
          {trackThreeLive && <LiveCard session={trackThreeLive} isLive={false} />}
          {trackFourLive && <LiveCard session={trackFourLive} isLive={false} />}
        </div>
      </div>
      <a href="#about">
        <div className="desc_scroll">
          <span>Scroll</span>
        </div>
      </a>
    </div>
  );
};

export default BeforeLiveOfDayTwo;
