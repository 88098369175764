import React, { FC } from 'react';

import MainTitleLive from '@/assets/img/main_title_live@2x.png';
import MainTitleLiveMobile from '@/assets/img/mobile/main_title@2x.png';
import { useLiveCardList } from '@/hooks/live';
import LiveCard from './LiveCard';
import NoLiveCard from './NoLiveCard';
import { getDay } from '@/utils/utility';

const LiveCardList: FC = () => {
  const { trackOneLive, trackTwoLive, trackThreeLive, trackFourLive } = useLiveCardList();

  return (
    <div className="main_top type_live">
      <div className="live_area">
        <div className="live_title">
          <h2 className="title">
            <img src={MainTitleLive} className="pc" alt="LINE DEVELOPER DAY 2021" />
            <img src={MainTitleLiveMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
            <span className="text_gradient">{`Day ${getDay()}`}</span>
          </h2>
          <p className="desc">
            <span>NOVEMBER 10-11</span>
            <span className="font_en">@</span>
            <span>ONLINE</span>
          </p>
        </div>
        <div className="live_list">
          {trackOneLive ? <LiveCard session={trackOneLive} /> : <NoLiveCard track={1} />}
          {trackTwoLive ? <LiveCard session={trackTwoLive} /> : <NoLiveCard track={2} />}
          {trackThreeLive ? <LiveCard session={trackThreeLive} /> : <NoLiveCard track={3} />}
          {trackFourLive ? <LiveCard session={trackFourLive} /> : <NoLiveCard track={4} />}
        </div>
      </div>
      <a href="#about">
        <div className="desc_scroll">
          <span>Scroll</span>
        </div>
      </a>
    </div>
  );
};

export default LiveCardList;
