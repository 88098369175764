import React, { FC } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { Session } from '@/types/session';
import { useLiveCard } from '@/hooks/live';

type Props = {
  session: Session;
  isLive?: boolean;
};

const LiveCard: FC<Props> = (props) => {
  const { session, isLive = true } = props;
  const { categories, title, track, durationTime, speakers, avatar, url } = useLiveCard(session);

  return (
    <>
      {isLive ? (
        <Link to={url} className="live_item">
          <p className="category">{categories}</p>
          <h5 className="title">{title}</h5>
          <div>
            <span className="badge on_air">On Air</span>
          </div>
          <p className="info">
            <span className="time">{durationTime}</span>
            <span className="track">{track}</span>
          </p>
          <p className="speaker">{speakers}</p>
          <div className="character">
            <img src={avatar} alt="" />
          </div>
        </Link>
      ) : (
        <div className="live_item">
          <p className="category">{categories}</p>
          <h5 className="title">{title}</h5>
          <div>
            <span className="badge off_air">Off Air</span>
          </div>
          <p className="info">
            <span className="time">{durationTime}</span>
            <span className="track">{track}</span>
          </p>
          <p className="speaker">{speakers}</p>
          <div className="character">
            <img src={avatar} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default LiveCard;
