import React from 'react';
import liff from '@/utils/liff';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useAuthStore } from '@/hooks/auth';

const LoginContent: React.FC = () => {
  const { setIsShowPopup } = useAuthStore();

  const handleLogin = async () => {
    const isLoggedIn = liff.isLoggedIn();

    if (!isLoggedIn) {
      await liff.login({ redirectUri: location.href });
    } else {
      setIsShowPopup(true);
    }
  };
  const { t } = useTranslation();

  return (
    <div className="section_info">
      <h2 className="title">{t('login.title')}</h2>
      <ul className="icon_group">
        <li className="icon_item">
          <i className="icon bookmark">Bookmark</i>
        </li>
        <li className="icon_item">
          <i className="icon help">Question</i>
        </li>
        <li className="icon_item">
          <i className="icon line">Line messenger</i>
        </li>
      </ul>
      <p className="desc" dangerouslySetInnerHTML={{ __html: t('login.desc') }}></p>
      <p className="desc gray">{t('login.notice')}</p>
      <button className="btn_large" onClick={handleLogin}>
        {t('login.button')}
      </button>
    </div>
  );
};

export default LoginContent;
