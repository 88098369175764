import React, { FC, useRef, useEffect, useMemo, HTMLAttributes } from 'react';

import { VideoData } from '@/types/live';

require('@linecorp/uvp/lib/skin/default.desktop.css');

type Props = {
  videoInfo: VideoData;
  onChangeLanguage: (string) => void;
  onEnded: () => void;
} & HTMLAttributes<HTMLDivElement>;

const LivePlayer: FC<Props> = (props) => {
  const { videoInfo, onChangeLanguage, onEnded } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  const thumbnail = useMemo(() => {
    return videoInfo['720P'].thumbnail;
  }, [videoInfo]);

  useEffect(() => {
    const Video = require('@linecorp/uvp').VideoHLS;
    const UI = require('@linecorp/uvp').UI;
    const video = new Video(videoRef.current as HTMLVideoElement);

    const resolution = new UI.Resolution({
      defaultName: '720P',
    }).setList([
      {
        name: '360P',
        src: videoInfo['360P'].url,
      },
      {
        name: '480P',
        src: videoInfo['480P'].url,
      },
      {
        name: '720P',
        src: videoInfo['720P'].url,
      },
      {
        name: '1080P',
        src: videoInfo['1080P'].url,
      },
    ]);

    const Caption = new UI.Caption({ defaultName: 'None' });

    Caption.setList([
      {
        name: 'Korean',
        src: '',
        srclang: 'ko',
      },
      {
        name: 'English',
        src: '',
        srclang: 'en',
      },
      {
        name: 'Japanese',
        src: '',
        srclang: 'ja',
      },
    ]);

    video.addEventListener(UI.Caption.events.clickListItem, (events) => {
      onChangeLanguage(events.detail.name);
      Caption.selectItem(events.detail.name);
    });

    const controls = {
      PlayPause: new UI.PlayPause(),
      Volume: new UI.Volume(),
      CurrentTime: new UI.CurrentTime(),
      Seekbar: new UI.Seekbar(),
      Duration: new UI.Duration(),
      Fullscreen: new UI.Fullscreen(),
      Ended: new UI.Ended({ timeout: 5 }),
      Live: new UI.Live(),
      Caption,
    };

    video.controls = {
      overlay: [controls.Seekbar, controls.PlayPause, controls.Ended, controls.Caption, resolution],
      toolbar: [
        controls.Seekbar,
        controls.PlayPause,
        controls.Volume,
        controls.Duration,
        controls.CurrentTime,
        controls.Caption,
        resolution,
        controls.Fullscreen,
      ],
    };

    if (videoRef.current?.autoplay) {
      videoRef.current.autoplay = true;
    }
  }, [videoInfo]);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <video
          style={{ width: '100%', height: '100%' }}
          ref={videoRef}
          autoPlay
          playsInline
          poster={thumbnail}
          onEnded={onEnded}
        />
      </div>
    </>
  );
};

export default LivePlayer;
