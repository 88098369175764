import React, { FC } from 'react';

type Props = {
  track: number;
};

const NoLiveCard: FC<Props> = (props) => {
  const { track } = props;

  return (
    <div className="live_item is_end">
      <p className="category">{`Track ${track}`}</p>
      <h5 className="title">{`Today's session has ended.`}</h5>
    </div>
  );
};

export default NoLiveCard;
