import { api } from '@/commons/axios';
import { API_CONFIG } from '@/commons/config';
import { LiveUrls, LiveInfo, LiveErrorStatus } from '@/types/live';

export const fetchLiveUrls = async () => {
  const res = await api.request<LiveUrls[]>({
    method: 'GET',
    url: `contents/projects/${API_CONFIG.PROJECT_ID}/collections/${API_CONFIG.COLLECTION.LIVE}/items`,
    timeout: 10000,
  });

  return res.data[0];
};

export const fetchLiveInfo = async (url: string) => {
  const res = await api.request<LiveInfo>({
    method: 'GET',
    url: url,
  });

  return res.data;
};

export const fetchLiveStatus = async () => {
  const res = await api.request<LiveErrorStatus[]>({
    method: 'GET',
    url: `contents/projects/${API_CONFIG.PROJECT_ID}/collections/${API_CONFIG.COLLECTION.LIVE_STATUS}/items`,
  });

  return res.data[0];
};
