import { useStaticQuery, graphql } from 'gatsby';

import { Category } from '@/types/session';

export const getCategory = () => {
  const {
    allLandPressCategory: { nodes: data },
  } = useStaticQuery<{ allLandPressCategory: { nodes: Category[] } }>(graphql`
    query getCategory {
      allLandPressCategory {
        nodes {
          name
          postId
          locale
        }
      }
    }
  `);

  return data;
};
