import React, { FC, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Lottie from 'react-lottie';
import classnames from 'classnames';

import BasicLayout from '@/layouts/Basic';
import SEO from '@/components/commons/SEO';

import mainVideo from '@/assets/img/mp4/logo_main.mp4';
import mainLoopVideo from '@/assets/img/mp4/logo_loop.mp4';
import bgGreen from '@/assets/img/mp4/bg_green.mp4';
import bgBlue from '@/assets/img/mp4/bg_blue.mp4';
import icon1Lottie from '@/assets/img/lottie/lottie_icon_01.json';
import icon2Lottie from '@/assets/img/lottie/lottie_icon_02.json';
import icon3Lottie from '@/assets/img/lottie/lottie_icon_03.json';
import icon4Lottie from '@/assets/img/lottie/lottie_icon_04.json';
import icon5Lottie from '@/assets/img/lottie/lottie_icon_05.json';
import icon6Lottie from '@/assets/img/lottie/lottie_icon_06.json';
import PosterImage from '@/assets/img/poster.png';

import lottieTechnology from '@/assets/img/lottie/lottie_technology.json';

import mainTitleImage from '@/assets/img/main_title@2x.png';
import mainTitleImageMobile from '@/assets/img/mobile/main_title@2x.png';
import Session from '@/components/Session';
import { useAuthStore } from '@/hooks/auth';
import { useRegistrationModal } from '@/hooks/registration';
import { useNoSlashReplace } from '@/hooks/common';
import { useBeforeunloadLogout } from '@/hooks/auth';
import { throttle } from 'lodash';
import LoginModal from '@/components/RegistrationModal/LoginModal';
import { getTimeType } from '@/utils/utility';
import { TimeType } from '@/types/time';
import { formatMainTopComponent } from '@/commons/formatter';
import { useMain } from '@/hooks/main';

type Props = {
  metaDescription?: string;
  metaImage?: string;
};

const Page: FC<Props> = ({ metaDescription, metaImage }) => {
  const { me } = useAuthStore();
  const { isShowLoginModal, handleOpenLoginModal, handleCloseLoginModal } = useRegistrationModal();
  const timeType = getTimeType();
  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const video3Ref = useRef<HTMLVideoElement>(null);
  const video4Ref = useRef<HTMLVideoElement>(null);

  const { t } = useTranslation();
  useBeforeunloadLogout();

  useNoSlashReplace();
  useMain();

  const [loopVideoEnabled, setLoopVideoEnabled] = useState(false);
  const [lottieTechnologyEnabled, setLottieTechnologyEnabled] = useState(false);
  const [icon1Enabled, setIcon1Enabled] = useState(false);
  const [icon2Enabled, setIcon2Enabled] = useState(false);
  const [icon3Enabled, setIcon3Enabled] = useState(false);
  const [icon4Enabled, setIcon4Enabled] = useState(false);
  const [icon5Enabled, setIcon5Enabled] = useState(false);
  const [icon6Enabled, setIcon6Enabled] = useState(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!video1Ref.current || !video2Ref.current || !video3Ref.current || !video4Ref.current) return;
    video1Ref.current.muted = true;
    video1Ref.current.defaultMuted = true;

    video2Ref.current.muted = true;
    video2Ref.current.defaultMuted = true;

    video3Ref.current.muted = true;
    video3Ref.current.defaultMuted = true;

    video4Ref.current.muted = true;
    video4Ref.current.defaultMuted = true;
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const windowHeight = window.innerHeight;
      const lottieTechnologyElement = document.querySelector('#lottie_technology');
      const textChallengeElement = document.querySelector('#text_challenge');
      const descElement = document.querySelector('#tech_desc');
      const icon1Element = document.querySelector('.icon1');
      const icon2Element = document.querySelector('.icon2');
      const icon3Element = document.querySelector('.icon3');
      const icon4Element = document.querySelector('.icon4');
      const icon5Element = document.querySelector('.icon5');
      const icon6Element = document.querySelector('.icon6');

      const handler = throttle(() => {
        if (
          textChallengeElement &&
          textChallengeElement.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)
        ) {
          textChallengeElement.classList.add('is_active');
        }
        if (
          lottieTechnologyElement &&
          lottieTechnologyElement.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)
        ) {
          setLottieTechnologyEnabled(true);
        }
        if (descElement && descElement.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          descElement.classList.add('is_active');
        }
        if (icon1Element && icon1Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon1Enabled(true);
        }
        if (icon2Element && icon2Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon2Enabled(true);
        }
        if (icon3Element && icon3Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon3Enabled(true);
        }
        if (icon4Element && icon4Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon4Enabled(true);
        }
        if (icon5Element && icon5Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon5Enabled(true);
        }
        if (icon6Element && icon6Element.getBoundingClientRect().top - windowHeight <= -(windowHeight * 0.167)) {
          setIcon6Enabled(true);
        }
      }, 100);

      window.addEventListener('scroll', handler);
      window.addEventListener('resize', handler);

      return () => {
        window.removeEventListener('scroll', handler);
        window.removeEventListener('resize', handler);
      };
    }
  });

  useEffect(() => setIsMounted(true), []);

  return (
    <>
      <SEO description={metaDescription} image={metaImage} />
      <BasicLayout>
        {isMounted && (
          <>
            {timeType === TimeType.BEFORE_DEVDAY && (
              <div className="main_top">
                <div className="txt_area">
                  <p className="title">
                    <img src={mainTitleImage} className="pc" alt="LINE DEVELOPER DAY 2021" />
                    <img src={mainTitleImageMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
                  </p>
                  <p className="desc" dangerouslySetInnerHTML={{ __html: t('top.key_visual.schedule') }} />
                  {me ? (
                    <Link to="/#sessions" className="btn type2">
                      <span>{t('top.key_visual.view_sessions')}</span>
                    </Link>
                  ) : (
                    <>
                      <button type="button" className="btn" onClick={handleOpenLoginModal}>
                        <span>{t('top.key_visual.registration')}</span>
                      </button>
                      <LoginModal visible={isShowLoginModal} onClose={handleCloseLoginModal} />
                    </>
                  )}
                </div>
                <div className="visual_area">
                  <video
                    ref={video1Ref}
                    data-autoplay
                    autoPlay
                    playsInline
                    muted
                    poster={PosterImage}
                    onEnded={() => {
                      setLoopVideoEnabled(true);
                    }}
                    className="logo_main_video"
                  >
                    <source src={mainVideo} type="video/mp4" />
                  </video>
                  <video
                    ref={video2Ref}
                    data-autoplay
                    autoPlay
                    playsInline
                    muted
                    poster={PosterImage}
                    loop
                    className="logo_loop"
                    style={{ display: loopVideoEnabled ? 'block' : 'none' }}
                  >
                    <source src={mainLoopVideo} type="video/mp4" />
                  </video>
                </div>
                <Link to="/#about" className="desc_scroll">
                  <span>{t('top.key_visual.scroll')}</span>
                </Link>
              </div>
            )}
            {timeType === TimeType.ENDED_FESTIVAL && (
              <div className="main_top">
                <div className="txt_area">
                  <p className="title">
                    <img src={mainTitleImage} className="pc" alt="LINE DEVELOPER DAY 2021" />
                    <img src={mainTitleImageMobile} className="mobile" alt="LINE DEVELOPER DAY 2021" />
                  </p>
                  <p className="desc" dangerouslySetInnerHTML={{ __html: t('top.key_visual.schedule') }} />
                  <Link to="/#sessions" className="btn type2">
                    <span>{t('top.key_visual.view_sessions')}</span>
                  </Link>
                </div>
                <div className="visual_area">
                  <video
                    ref={video1Ref}
                    data-autoplay
                    autoPlay
                    playsInline
                    muted
                    poster={PosterImage}
                    onEnded={() => {
                      setLoopVideoEnabled(true);
                    }}
                    className="logo_main_video"
                  >
                    <source src={mainVideo} type="video/mp4" />
                  </video>
                  <video
                    ref={video2Ref}
                    data-autoplay
                    autoPlay
                    playsInline
                    muted
                    poster={PosterImage}
                    loop
                    className="logo_loop"
                    style={{ display: loopVideoEnabled ? 'block' : 'none' }}
                  >
                    <source src={mainLoopVideo} type="video/mp4" />
                  </video>
                </div>
                <Link to="/#about" className="desc_scroll">
                  <span>{t('top.key_visual.scroll')}</span>
                </Link>
              </div>
            )}
            {timeType !== TimeType.ENDED_FESTIVAL && timeType !== TimeType.BEFORE_DEVDAY && (
              <>{formatMainTopComponent(timeType)}</>
            )}
          </>
        )}
        <div className="main_challenge" id="about">
          <div className="content_w1100">
            <div className="text_area">
              <h2 className="title">
                <span id="text_challenge">Our Challenge in</span>
                <span id="lottie_technology" className={classnames({ is_active: lottieTechnologyEnabled })}>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: lottieTechnologyEnabled ? lottieTechnology : null,
                    }}
                  />
                </span>
              </h2>
              <p id="tech_desc" className="desc">
                {t('top.overview.text')}
              </p>
            </div>
            <div className="icon_area">
              <div className={classnames('icon1', { is_active: icon1Enabled })}>
                <div id="lottie_icon_01" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon1Enabled ? icon1Lottie : null,
                    }}
                  />
                </div>
              </div>
              <div className={classnames('icon2', { is_active: icon2Enabled })}>
                <div id="lottie_icon_02" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon2Enabled ? icon2Lottie : null,
                    }}
                  />
                </div>
              </div>
              <div className={classnames('icon3', { is_active: icon3Enabled })}>
                <div id="lottie_icon_03" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon3Enabled ? icon3Lottie : null,
                    }}
                  />
                </div>
              </div>
              <div className={classnames('icon4', { is_active: icon4Enabled })}>
                <div id="lottie_icon_04" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon4Enabled ? icon4Lottie : null,
                    }}
                  />
                </div>
              </div>
              <div className={classnames('icon5', { is_active: icon5Enabled })}>
                <div id="lottie_icon_05" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon5Enabled ? icon5Lottie : null,
                    }}
                  />
                </div>
              </div>
              <div className={classnames('icon6', { is_active: icon6Enabled })}>
                <div id="lottie_icon_06" className="lottie_svg">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: icon6Enabled ? icon6Lottie : null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content_w1100">
          <div id="bg_green" className="bg_video green is_active">
            <video ref={video3Ref} data-autoplay autoPlay playsInline muted poster="">
              <source src={bgGreen} type="video/mp4" />
            </video>
          </div>
          <div id="bg_blue" className="bg_video blue is_active">
            <video ref={video4Ref} data-autoplay autoPlay playsInline muted poster="">
              <source src={bgBlue} type="video/mp4" />
            </video>
          </div>
          <Session />
        </div>
      </BasicLayout>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Page;
