import { RefObject, useEffect, useRef } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

export const useOuterClose = (container: RefObject<HTMLDivElement>, setter: () => void) => {
  const handleOuterClose = (event: MouseEvent) => {
    const isInContainer = container.current?.contains(event.target as Node);

    if (!isInContainer) {
      setter();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOuterClose, { capture: true });

    return () => {
      window.removeEventListener('click', handleOuterClose, { capture: true });
    };
  }, []);
};

export const useNoSlashReplace = () => {
  useEffect(() => {
    if (location.pathname[location.pathname.length - 1] !== '/') {
      location.pathname += '/';
    }
  }, []);
};

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);

      return () => {
        clearTimeout(id);
      };
    }
  }, [delay]);
};

export const useWindowFocus = (callback: () => void) => {
  useEffect(() => {
    if (!window !== undefined) {
      const handler = () => {
        callback();
      };
      window.addEventListener('focus', handler);

      return () => {
        window.removeEventListener('focus', handler);
      };
    }
  }, []);
};

export const useTimezoneSuffix = () => {
  const { language } = useI18next();

  return language === 'ko' ? 'KST' : 'JST';
};
